<template>
  <div>


    <b-carousel class="carousel-1-normal" v-if="foto_homepage !== null && foto_homepage.length > 0"
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
          v-for="photo in foto_homepage"
          :key="photo.id"
          :img-src="photo.img"
          class="custom-slider-img"
      ></b-carousel-slide>
    </b-carousel>


    <!-- Conttatti/email/indicazioni visibili solo mobile -->
    <div
        class="container-info-agency-mobile"
        :style="{'background-color': sfondo_3, 'background-image': 'none' }">
      <div class="container-80" :style="{'background-color': sfondo_3 }">
        <div class="center-contact-mobile" :style="{'background-color': sfondo_3 }">
          <div class="icon">
            <a href="https://www.google.de/maps?q=41.691306,12.779738&amp;z=20&amp;t=k">
              <IconaIndicazioniStradali :style="{'fill': testo_sfondo_3 + '!important'}"
                                        class="icon-indicazioni"></IconaIndicazioniStradali>
            </a>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Indicazioni</span>
          </div>
          <div class="icon" id="contactAgencyButton" @click="modalContact">
            <IconaTelefono :style="{'fill': testo_sfondo_3 + '!important'}"
                           class="icon-telephone-mobile"></IconaTelefono>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Telefoni</span>
          </div>
          <div class="icon">
            <a :href="`mailto:${email}`" class="mailto-email">
              <IconaEmail :style="{'fill': testo_sfondo_3 + '!important'}" class="icon-email"></IconaEmail>
            </a>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Email</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /Conttatti/email/indicazioni visibili solo mobile -->

    <!-- Descrizione agenzia -->
    <div class="container-bg-agency-home"
         :style="{'background-color': sfondo_1 }"
    >
      <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 agency-detail">
        <b-row id="agency-image-not-update">
          <b-col md="4" xs="12">
            <h1 class="text-color nome-azienda-homepage" :style="{'color': testo_sfondo_1 + '!important'}">{{
                nome_azienda
              }}</h1>
          </b-col>
          <b-col md="8" xs="12" class="text-color">
            <span id="description" :style="{'color': testo_sfondo_1 + '!important'}"
                  v-html="chi_siamo">
            </span>
            <div v-if="chi_siamo !== null && chi_siamo.length > 600" class="container-80" id="read-all-desktop"
                 :style="{'color': testo_sfondo_1 + '!important'}">
              <span @click="showhideText" :style="{'color': testo_sfondo_1 + '!important'}">Leggi tutto</span>
            </div>
            <div v-if="chi_siamo !== null && chi_siamo.length > 370" class="container-80" id="read-all-mobile"
                 :style="{'color': testo_sfondo_1 + '!important'}">
              <span @click="showhideText" :style="{'color': testo_sfondo_1 + '!important'}">Leggi tutto</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <br>
    </div>
    <!-- /Descrizione agenzia -->

    <!-- Modale  -->
    <div id="contactModal" class="modale" :style="{ 'color':testo_sfondo_1 ,'background-color': sfondo_1 }">
      <div class="modal-contact">
        <div class="closeModal">
          <span>&times;</span>
        </div>
        <div class="allSediHomepage">
          <h4 class="call-numbers">Chiama</h4>
          <div>
            <span @click="callNumberSelect(telefono)">{{ telefono }}</span>
          </div>
          <div v-for="(item) in sedi" :key="item.id">
            <span :id="item.id" @click="callNumberSelect(item.telefono)">{{ item.telefono }}</span>
          </div>
          <div v-for="(item) in cellulari" :key="item.id">
            <span :id="item.id" @click="callNumberSelect(item.cellulare)">{{ item.referente }} {{
                item.cellulare
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modale -->
    <!-- Map visible only mobile -->
    <b-row class="maps d-md-none">
      <b-col xs="12">
        <l-map style="height: 200px; width:100%"
               :zoom="zoom"
               :center="geolocation"
               gestureHandling
               :options="mapOptions"
        >
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="geolocation">
          </l-marker>
        </l-map>
      </b-col>
    </b-row>
    <!-- /Map visible only mobile -->
    <!-- Address visible only mobile -->
    <div class="section-address-mobile d-md-none" :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="address-mobile">
        <h3>Indirizzo</h3>
        <span>{{ via }} - {{ cap }} {{ comune }}</span>
      </div>
    </div>
    <!-- /Address visible only mobile -->
    <div
        :style="{'background-color': sfondo_3 }"
    >
      <div class="necrologi"
           :style="{'background-color': sfondo_3 }">
        <h2 class="title-last-necrologi" :style="{ 'color': testo_sfondo_3 + '!important' }">Ultimi Necrologi</h2>

        <div
            class="all-necrologi"
            v-if="necrologi !== null && necrologi.length > 0"
        >
          <div v-for="item in necrologi.slice(0,5)" :key="item.id" class="item-necrologio">

            <router-link :to="{name: 'DettaglioNecrologio', params: {id: item.id}}">

              <div class="necrologio_div" :style="{'background-color':sfondo_1}">
                <figure class="image is-128x128 center_in_div_necrologi">
                  <a href="#">

                    <v-lazy-image
                        class="is-rounded necrologi_foto"
                        :src="getPhotoNecrologioFromCdn(item)"
                        :alt="item.nome"
                    />

                  </a>
                </figure>
                <br>
                <h1 class="nome_necrologio_center" :style="{'color': testo_sfondo_1 + '!important'}">{{
                    item.nome
                  }}</h1>
                <span class="eta_necrologio" :style="{'color': testo_sfondo_1 + '!important', 'opacity': 0.4}">{{
                    calculateYears(item.data_nascita, item.data_morte)
                  }} anni</span>
                <div class="eta_necrologio_div">
                  <span class="data_nascita_sinistra" :style="{'color': testo_sfondo_1 + '!important', 'opacity': 0.4}">{{
                      dataNecrologio(item.data_nascita)
                    }}</span>
                  <span class="data_morte_destra" :style="{'color': testo_sfondo_1 + '!important', 'opacity': 0.4}">{{
                      dataNecrologio(item.data_morte)
                    }}</span>
                </div>
              </div>
            </router-link>

          </div>
        </div>
      </div>
    </div>


    <div class="last-manifesti"
         v-if="manifesti !== null && manifesti.length > 0"
         :style="{'background-color': sfondo_1 }">
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="last-manifesti-80">
        <h2 class="title-manifesti" :style="{'color': testo_sfondo_1 + '!important'}">Ultimi Manifesti</h2>
        <b-row class="item-manifesti">
          <b-col v-for="item in manifesti.slice(0,3)" :key="item.id" md="4" cols="10" class="item-manifesto">

            <router-link :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

              <v-lazy-image
                  class="photo-manifesto"
                  :src="getPhotoManifestoFromCdn(item)"
                  :alt="item.nome_manifesto"
              />

            </router-link>
          </b-col>
        </b-row>
        <br>
      </div>
    </div>

    <div
        v-if="servizi!== null && servizi.length !== 0"
        class="services"
        :style="{'background-color': sfondo_3 }"
    >
      <div class="container-80">
        <h2 class="title-services" :style="{'color': testo_sfondo_3 + '!important'}">Servizi</h2>
        <b-row class="all-services">
          <b-col md="2" class="item-services" v-for="(value, propertyName) in servizi" :key="propertyName">

            <object
                type="image/svg+xml"
                :style="{'background-color': sfondo_1 }"
                class="logo-services"
                :data="findIconServizio(propertyName)"
            >
            </object>

            <h4 class="title-service" :style="{'color': testo_sfondo_3 + '!important'}">{{ propertyName }}</h4>
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Servizi Mobile -->
      <div class="d-md-none">
        <div :class="{ active_services : active_el === index }" class="img-center"
             v-for="(value, propertyName, index) in servizi" :key="propertyName" :id="'servizio'+index">

          <object
              type="image/svg+xml"
              :style="{'background-color': sfondo_1 }"
              class="logo-services"
              :data="findIconServizio(propertyName)"></object>

          <div class="item-services-mobile">
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </div>
          <div class="btn btn-prev" aria-label="Previous slide" @click="activate(index - 1)">
            &#10094;
          </div>
          <div class="btn btn-next" aria-label="Next slide" @click="activate(index + 1)">
            &#10095;
          </div>
        </div>
      </div>
      <!-- /Servizi Mobile -->
    </div>

    <div
        class="agency-map"
        :style="{'background-color': sfondo_1 }"
    >
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 structure-map">
        <b-row class="all-services">
          <b-col md="3">
            <div class="info-maps">
              <h4
                  class="address"
                  :style="{'color': testo_sfondo_1 + '!important'}">{{ via }} -
                {{ cap }}
                {{ comune }}</h4>
              <span id="email" :style="{'color': testo_sfondo_1 + '!important'}">email:</span>
              <span :style="{'color': testo_sfondo_1 + '!important'}">{{ email }}</span>
              <span class="agency-telephone" :style="{'color': testo_sfondo_1 + '!important'}">telefono:</span>
              <span :style="{'color': testo_sfondo_1 + '!important'}">{{ telefono }}</span>
              <span
                  v-if="cellulari !== undefined && cellulari.length > 0"
                  class="agency-cellular"
                  :style="{'color': testo_sfondo_1 + '!important'}"
              >cellulare:</span>

              <span
                  v-for="item in cellulari" :key="item.id"
                  :style="{'color': testo_sfondo_1 + '!important'}"
              >
                  {{ item.referente }} {{ item.cellulare }}
              </span>
            </div>
          </b-col>
          <b-col md="7">
            <div class="maps map-sede-pricipale d-none d-md-block">
              <l-map
                  class="item-map-sede-principale"
                  style="height: 400px; width:100%;"
                  :zoom="zoom"
                  :center="geolocation"
                  gestureHandling
                  :options="mapOptions"
              >
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="geolocation"></l-marker>
              </l-map>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Altre sedi -->
    <div :style="{'background-color': sfondo_3 }">
      <div v-if="sedi !== null && sedi.length > 0" class="other-locations"
           :style="{'background-color': sfondo_3 }">
        <h2 class="title-other-locations" :style="{'color': testo_sfondo_3 + '!important'}">Altre Sedi</h2>
        <div class="other-maps">
          <div>
            <router-link to="/contatti">
              <b-row class="single-map">
                <b-col
                    v-for="item in sedi"
                    :key="item.id"
                    md="4"
                    cols="8"
                    class="left-margin item-map-home">

                  <l-map class="item-map"
                         gestureHandling
                         :options="mapOptions"
                         :zoom="zoom"
                         :center="getNormalizedGeolocation(item.geolocation)"
                  >
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-marker :lat-lng="getNormalizedGeolocation(item.geolocation)"></l-marker>
                  </l-map>
                  <div class="over-map"
                       :style="{'color': testo_sfondo_1 + '!important', 'background-color': sfondo_1}">
                    <span>{{ item.address }}</span>
                    <span>{{ item.telefono }}</span>
                    <span class="cap">{{ item.cap.istat }} {{ item.cap.comune }}</span>
                  </div>
                </b-col>
              </b-row>
            </router-link>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import VLazyImage from "v-lazy-image/v2";
import {GestureHandling} from "leaflet-gesture-handling";
import IconaIndicazioniStradali from '@/assets/TemplateImg/indicazioni_stradali.svg?inline'
import IconaEmail from '@/assets/TemplateImg/email.svg?inline'
import IconaTelefono from '@/assets/TemplateImg/telefono.svg?inline'
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import {mapGetters} from "vuex";
import {
  iconaServizio,
  normalizeDataNascita,
  yearsCalculate,
  photoManifestoFromCDN,
  photoNecrologioFromCDN,
  normalizeGeolocation
} from "@/utils/util";


export default {
  name: "Homepage",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    IconaIndicazioniStradali,
    IconaEmail,
    IconaTelefono,
    // eslint-disable-next-line vue/no-unused-components
    GestureHandling,
    VLazyImage
  },

  data() {

    return {
      slide: 0,
      sliding: null,
      text_visibility: null,
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      active_el: 0,
      mapOptions: {
        zoomSnap: 0.5,
        gestureHandling: true
      }
    }
  },

  computed: {

    ...mapGetters([
      'cellulari',
      'manifesti',
      'necrologi',
      'chi_siamo',
      'nome_azienda',
      'cap',
      'telefono',
      'comune',
      'via',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'texture',
      'email',
      'template',
      'geolocation',
      'icone',
      'sedi',
      'servizi',
      'foto_azienda',
      'foto_homepage',
    ]),

  },

  metaInfo() {
    return {
      title: 'Home - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },


  methods: {


    getNormalizedGeolocation(value) {
      return normalizeGeolocation(value)
    },

    findIconServizio(name) {
      return iconaServizio(name, this.icone)
    },

    dataNecrologio(data_nascita) {
      return normalizeDataNascita(data_nascita)
    },

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    getPhotoNecrologioFromCdn(item) {
      return photoNecrologioFromCDN(item)
    },

    getPhotoManifestoFromCdn(item) {
      return photoManifestoFromCDN(item)
    },

    activate: function (el) {
      if (el === 4) {
        el = 0;
      }
      if (el === -1) {
        el = 3;
      }
      this.active_el = el;
    },

    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false
    },

    showhideText: function () {

      this.text_visibility = document.getElementById("description");
      this.text_visibility.style.display = 'block';
      this.text_visibility.style.margin = '0 0 30px 0';
      this.text_visibility = document.getElementById("read-all-mobile");
      this.text_visibility.style.display = 'none';
      this.text_visibility = document.getElementById("read-all-desktop");
      this.text_visibility.style.display = 'none';
    },


    modalContact() {
      let modal = document.getElementById("contactModal");
      let span = document.getElementsByClassName("closeModal")[0];
      modal.style.display = "block";

      span.onclick = function () {
        modal.style.display = "none";
      }

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      }
    },

    callNumberSelect(telephone) {
      document.location.href = "tel:" + telephone;
    },

    handleScroll() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById("carousel-1").classList.add("carousel-1-scroll");
        document.getElementById("carousel-1").classList.remove("carousel-1-normal");
      } else {
        document.getElementById("carousel-1").classList.remove("carousel-1-scroll");
        document.getElementById("carousel-1").classList.add("carousel-1-normal");
      }
    },
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>


<style scoped>

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

a {
  color: white !important;
}

.text-color {
  color: white;
}

.text-color >>> span {
  color: red;
}

.container-bg-agency-home {
  position: relative;
  padding-bottom: 40px;
}

.texture > img {
  position: absolute;
  height: 100%;
  /* opacity: 0.1; */
  width: 100%;
  object-fit: cover;
}

.container-80 {
  width: 80%;
  margin: auto;
  color: black !important;
}

/* Slider */

.carousel-1-scroll {
  margin-top: 0;
}

.carousel-1-normal {
  margin-top: 80px;
}

/* /Slider */

#block-img-none {
  height: 80px;
}

.add-padding {
  padding-top: 60px;
}

.background-img-homepage {
  width: 100%;
  margin-top: 80px;
}

#description {
  color: black;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* #readAll {
  display: block;
  margin-top: 10px;
  text-align: end;
  color: #2D3384 !important;
} */

#read-all-desktop {
  display: block;
  margin-top: 10px;
  text-align: end;
  color: #2D3384 !important;
}

.agency-detail {
  padding-top: 40px;
  margin-bottom: 30px;
}

#read-all-mobile {
  display: none;
}

.contact-agency-homepage {
  padding-top: 30px;
  padding-bottom: 60px;
}

.center-contact {
  align-items: center;
}

.call {
  height: 50px;
  text-transform: uppercase;
  border-radius: 19px !important;
  background-color: white !important;
  color: #1E196C !important;
  border: none !important;
  font-weight: bold !important;
}

.address-cap {
  font-weight: bold;
  font-size: 18px;
}

.telephone {
  text-align: end;
  font-weight: bold;
  font-size: 23px;
}

.icon-telephone {
  height: 30px;
  vertical-align: text-bottom;
  margin-right: 10px;
}

.container-info-agency-mobile {
  display: none;
}

/* Sezione Ultimi Necrologi */

.necrologi {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  text-align: center;
}

.title-last-necrologi {
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #1B1464;
  margin-bottom: 40px;
}

.all-necrologi {
  display: flex;
  justify-content: space-between;
  overflow: auto;
}

/* @keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
} */


.necrologio_div {
  width: 165px;
  height: 245px;
  /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
  border-radius: 25px;
}

.is-rounded {
  border-radius: 15px;
  border: 2px solid white;
}

.center_in_div_necrologi {
  margin: 0 auto;
  background-color: transparent;
}

.is-128x128 {
  height: 128px;
  width: 128px;
  padding-top: 18.5px;
}

.necrologi_foto {
  height: 128px;
  width: 128px;
}

h1.nome_necrologio_center {
  /* color: white; */
  text-align: center;
  font-weight: 700;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 0 !important;
  margin: auto;
}

span.eta_necrologio {
  /* color: #8284BB; */
  text-align: center;
  font-weight: 600;
  font-size: 12px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.eta_necrologio_div {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
  /* color: #8284BB; */
  font-size: 12px;
}


.item-style {
  max-width: 12.8% !important;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
  border-radius: 8%;
  color: white;
}

.item-necrologi {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}

.photo-defunto {
  width: 110px;
  height: 110px;
  margin-top: 20px;
  border-radius: 50%;
}

.date-death {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  /* color: #8284BB; */
  padding-bottom: 10px;
}

.last-manifesti-80 {
  width: 80%;
  margin: auto;
}

.last-manifesti {
  position: relative;
  padding-bottom: 40px;
  /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
}

.item-manifesti {
  flex-wrap: nowrap !important;
  /* animation-duration: 5s;
  animation-name: slideini; */
}

/* @keyframes slideini {
  from {
    transform: translatey(100%);
  }

  to {
    transform: translatey(0%);
  }
} */

.title-manifesti {
  padding: 40px 0 40px 0;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
}

.photo-manifesto {
  width: 100%;
  border-radius: 25px;
}


.title-services {
  padding: 40px 0 40px 0;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #211A68;
}

.all-services {
  padding-bottom: 40px;
  justify-content: space-between;
}

.item-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo-services {
  width: 100px;
  height: 100px;
  margin-bottom: 40px;
  border-radius: 25px;
}

.title-service {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.item-services > span {
  margin-top: 5px;
  font-size: 14px;
  color: grey;
}

.agency-map {
  position: relative;
  /* padding-top: 40px; */
  /* background-image: linear-gradient(to right, #2E3192, #1C1565); */
}

.map-sede-pricipale {
  margin-top: 40px;
  z-index: 1;
}

/* Mappa Agenzia */
.info-maps {
  display: flex;
  flex-direction: column;
  color: white;;
}

.address {
  font-weight: 600;
  margin-top: 40px;
}

#email {
  margin-top: 30px;
}

.agency-telephone {
  margin-top: 20px;
}

.agency-cellular {
  margin-top: 20px;
}

/* Section Altre Sedi  */
.title-other-locations {
  font-size: 30px;
  padding: 40px 0 20px 0;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #1B1464;
}

.other-locations {
  width: 80%;
  margin: auto;
  margin-bottom: 40px;;
}

.left-margin {
  margin: 20px 0px 40px 0;
}

.item-map-sede-principale {
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  z-index: 1;
}

.item-map {
  height: 400px !important;
  width: 100%;
  position: relative;
  z-index: -1;
  border-bottom-right-radius: 38px;
  border-bottom-left-radius: 38px;
  border-top-right-radius: 38px;
  border-top-left-radius: 38px;
}

.over-map {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  padding: 20px;
  bottom: 0;
  left: 15px;
  text-align: center;
  border-bottom-right-radius: 38px;
  border-bottom-left-radius: 38px;
  border-top-right-radius: 38px;
  border-top-left-radius: 38px;
  color: white;
  background-color: #52539D;
  opacity: 0.9;
}

.item-map-home {
  z-index: 1;
}

.other-locations-mobile {
  display: none;
}

.cap {
  font-weight: 600;
}


@media (min-width: 1024px) and (max-width: 1439px) {
  .necrologio_div {
    width: 155px !important;
  }

  .is-128x128 {
    padding-top: 13.5px;
  }

  .address-cap {
    font-size: 13px;
  }

  .telephone {
    font-size: 18px;
  }

  .icon-telephone {
    height: 25px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  #block-img-none {
    display: none;
  }

  .necrologio_div {
    width: 155px !important;
    margin-right: 20px;
  }

  .is-128x128 {
    height: 128px;
    width: 128px;
    padding-top: 13.5px;
  }

  .necrologio_div:first-child {
    margin-left: 20px;
  }

  .address-cap {
    font-size: 13px;
  }

  .telephone {
    font-size: 13px;
  }

  .icon-telephone {
    height: 20px;
  }

  /* .container-info-agency-mobile {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .icon {
    display: flex;
    width: 33.3%;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .icon-indicazioni, .icon-email, .icon-telephone-mobile {
    width: 70px;
  }

  .icon > span {
    margin-top: 10px;
  } */
}

@media (min-width: 1024px) {

  .necrologi {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 767.98px) {

  /* #block-img-none {
   display: none;
 } */
  h1.text-color {
    color: #1B1464;
    text-align: center;
  }

  .nome-azienda-homepage {
    font-size: 25px;
  }

  .background-img-homepage {
    margin-top: 0px;
  }

  .container-bg-agency-home {
    /* background-color: white; */
    background-image: none;
    padding-bottom: 0px;
  }

  .agency-detail {
    text-align: center;
    margin-bottom: 0px;
  }

  /* #readAll {
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: end;
    color: #2D3384 !important;
  } */
  #read-all-desktop {
    display: none;
  }

  #read-all-mobile {
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: end;
    color: #2D3384 !important;
  }

  .contact-agency-homepage {
    display: none;
  }

  .contact-agency-mobile {
    background-color: white;
  }

  .center-contact-mobile {
    display: flex;
    /* padding-bottom: 40px; */
  }

  .icon {
    display: flex;
    width: 33.3%;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .icon-indicazioni, .icon-email, .icon-telephone-mobile {
    width: 70px;
  }

  .icon > span {
    margin-top: 10px;
  }

  .line {
    border: 0;
    border-top: 1px solid #FA8072;
    margin-bottom: 20px;
  }

  /*Address*/
  .section-address-mobile {
    position: relative;
    height: 150px;
    /* color: white;
    background-image: linear-gradient(to right, #2E3192, #1C1565); */
  }

  .address-mobile {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
  }

  .address-mobile > h3 {
    font-size: 18px;
  }

  .address-mobile > span {
    font-weight: bold;
    font-size: 20px;
  }

  /*Sezione Ultimi Necrologi*/
  .all-necrologi {
    flex-wrap: nowrap !important;
    justify-content: flex-start;
  }

  .necrologio_div {
    border-radius: 15px !important;
    margin-right: 20px;
    height: 220px;
    width: 135px !important;
  }

  .necrologio_div:first-child {
    margin-left: 20px;
  }

  .is-128x128 {
    height: 115px;
    width: 115px;
    padding-top: 10.5px;
  }

  .necrologi_foto {
    height: 115px;
    width: 115px;
  }

  .eta_necrologio_div {
    font-size: 10px;
    margin-top: 5px;
  }

  /*Sezione Ultimi Manifesti*/
  .last-manifesti-80 {
    width: 100%;
    margin: auto;
  }

  .last-manifesti {
    padding-bottom: 0;
  }

  .photo-manifesto {
    width: 90%;
    border-radius: 15px !important;
  }

  .item-manifesti {
    overflow: scroll;
  }

  .item-manifesti :first-child {
    margin-left: 8px;
  }

  /*Sezione Servizi*/
  .all-services {
    display: none !important;
  }

  #servizio0, #servizio1, #servizio2, #servizio3 {
    display: none;
  }

  .active_services {
    display: block !important;
  }

  .img-center {
    position: relative;
    text-align: center;
  }

  .btn-prev, .btn-next {
    position: absolute;
  }

  .btn-prev {
    top: 7%;
    left: 10%;
  }

  .btn-next {
    top: 7%;
    right: 10%;
  }

  .item-services {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    align-items: baseline;
  }

  .item-services-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  .item-services-mobile > span {
    margin-top: 5px;
    font-size: 14px;
    color: grey;
  }

  .agency-map {
    display: none;
  }

  /*Sezione Altre sedi*/
  .other-maps {
    overflow: scroll;
  }

  .other-maps-mobile {
    overflow: hidden;
  }

  .other-locations {
    width: 100%;
  }

  .other-locations-mobile {
    display: block;
    margin-bottom: 40px;
  }

  .over-map {
    font-size: 12px;
  }

  .single-map {
    position: relative;
    flex-wrap: nowrap !important;
  }

  .item-map {
    height: 250px !important;
  }

  .left-margin {
    /* margin-left: calc(100% - 83%); */
    margin-left: 20px;
  }

  .leaflet-control-attribution.leaflet-control {
    display: none;
  }

  .container-info-agency-mobile {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
  }

}

.leaflet-bar a, .leaflet-bar a:hover {
  color: #030303 !important;
}


/* Modale */

.modale {
  display: none;
  width: 100%;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%, 0);
  position: fixed;
  z-index: 10000000000;
  overflow: auto;
}

.modal-contact {
  margin: auto;
  padding: 0px 00px 20px 0px;
}

/* The Close Button */
.closeModal {
  text-align: end;
  color: white;
  font-size: 28px;
  font-weight: bold;
  margin-right: 20px;
}

.closeModal:hover,
.closeModal:focus {
  text-decoration: none;
  cursor: pointer;
}

.allSediHomepage {
  text-align: center;
}

.allSediHomepage > div {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid grey;
}

img.photo-img-slider {
  height: 50vw;
}

</style>
