<template>
  <div>
    <div class="container-carousel-mock2" :style="{ backgroundColor: sfondo_3 }">
      <div class="carousel-agency-mock2">
        <b-carousel class="carousel-mock2" v-if="foto_homepage !== null && foto_homepage.length > 0"
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    controls
                    indicators
                    background="#ababab"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
              v-for="photo in foto_homepage"
              :key="photo.id"
              :img-src="photo.img"
              class="custom-slider-img"
          ></b-carousel-slide>
        </b-carousel>
      </div>

      <div class="info-description-agency-mock2">
        <h1 class="text-color nome-azienda-homepage" :style="{'color': testo_sfondo_3 + '!important'}">
          {{ nome_azienda }}</h1>
        <div>
          <span class="testo-chi-siamo" :style="{'color': testo_sfondo_3 + '!important'}"
                v-html="chi_siamo">
          </span>
          <div v-if="chi_siamo !== null && chi_siamo.length > 600" id="read-all-desktop"
               :style="{'color': testo_sfondo_3 + '!important'}">
            <router-link to="/chi-siamo" :style="{'color': testo_sfondo_3 + '!important'}">Leggi tutto</router-link>
          </div>
        </div>
        <div class="contact-agency-number-mock2">
          <button class="call-agency-mock2" :style="{ backgroundColor: sfondo_1, color: testo_sfondo_1 }"
                  @click="callNumberSelect(telefono)">
            Chiamaci
          </button>
          <div>
            <Telephone
                :style="{'fill': sfondo_1}"
                class="icon-telephone-mock2"
            >
            </Telephone>
            <span :style="{'color':testo_sfondo_3}">{{ telefono }}</span>
          </div>
        </div>
        <span class="address-mock2" :style="{ borderBottom: createBorderBottom, color :testo_sfondo_3 }">{{ via }} - {{
            cap
          }} {{ comune }}</span>
      </div>
      <div class="left-background" :style="{ backgroundColor: sfondo_1 }">
        <div v-if="texture" class="texture">
          <img :src="texture" alt="">
        </div>
      </div>
    </div>
    <div class="component-logo-agency">
      <LogoAgency secondBcg="black"></LogoAgency>
    </div>
    <!-- Carousel-Logo-Agency Mobile  -->
    <div class="container-carousel-mock2-mobile" :style="{ backgroundImage: carosuelBcg }">
      <div class="carousel-agency-mock2">
        <b-carousel class="carousel-mock2" v-if="foto_homepage !== null && foto_homepage.length > 0"
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    controls
                    indicators
                    background="#ababab"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
              v-for="photo in foto_homepage"
              :key="photo.id"
              :img-src="photo.img"
              class="custom-slider-img"
          ></b-carousel-slide>
        </b-carousel>
      </div>
      <div class="left-background"></div>
    </div>
    <div class="component-logo-agency-mobile">
      <LogoAgency></LogoAgency>
    </div>
    <!-- Carousel-Logo-Agency Mobile  -->

    <!-- Conttatti/email/indicazioni visibili solo mobile -->
    <div
        class="container-info-agency-mobile"
        :style="{'background-color': sfondo_3, 'background-image': 'none' }">
      <div class="container-80" :style="{'background-color': sfondo_3 }">
        <div class="center-contact-mobile" :style="{'background-color': sfondo_3 }">
          <div class="icon">
            <a href="https://www.google.de/maps?q=41.691306,12.779738&amp;z=20&amp;t=k">
              <Address :style="{'fill': testo_sfondo_3 + '!important'}"
                       class="icon-indicazioni"></Address>
            </a>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Indicazioni</span>
          </div>
          <div class="icon" id="contactAgencyButton" @click="modalContact">
            <Number :style="{'fill': testo_sfondo_3 + '!important'}"
                    class="icon-telephone-mobile"></Number>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Telefoni</span>
          </div>
          <div class="icon">
            <a :href="`mailto:${email}`" class="mailto-email">
              <Mail :style="{'fill': testo_sfondo_3 + '!important'}" class="icon-email"></Mail>
            </a>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Email</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /Conttatti/email/indicazioni visibili solo mobile -->

    <!-- Descrizione agenzia -->
    <div class="container-bg-agency-home-mock2"
         :style="{'background-color': sfondo_1 }"
    >
      <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 agency-detail">
        <b-row id="agency-image-not-update">
          <b-col md="4" xs="12" class="tablet-screen">
            <h1 class="text-color nome-azienda-homepage" :style="{'color': testo_sfondo_1 + '!important'}">{{
                nome_azienda
              }}</h1>
          </b-col>
          <b-col md="8" xs="12" class="text-color tablet-screen">
            <span id="description" class="testo-chi-siamo" :style="{'color': testo_sfondo_1 + '!important'}"
                  v-html="chi_siamo">
            </span>
            <!-- <div v-if="chi_siamo !== null && chi_siamo.length > 600" class="container-80" id="read-all-desktop"
                 :style="{'color': testo_sfondo_1 + '!important'}">
              <span @click="showhideText" :style="{'color': testo_sfondo_1 + '!important'}">Leggi tutto</span>
            </div> -->
            <div v-if="chi_siamo !== null && chi_siamo.length > 370" class="container-80" id="read-all-mobile"
                 :style="{'color': testo_sfondo_1 + '!important'}">
              <span @click="showhideText" :style="{'color': testo_sfondo_1 + '!important'}">Leggi tutto</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <br>
    </div>
    <!-- /Descrizione agenzia -->

    <!-- Modale  -->
    <div id="contactModal" class="modale" :style="{ 'color':testo_sfondo_1 ,'background-color': sfondo_1 }">
      <div class="modal-contact">
        <div class="closeModal">
          <span>&times;</span>
        </div>
        <div class="allSediHomepage">
          <h4 class="call-numbers">Chiama</h4>
          <div>
            <span @click="callNumberSelect(telefono)">{{ telefono }}</span>
          </div>
          <div v-for="(item) in sedi" :key="item.id">
            <span :id="item.id" @click="callNumberSelect(item.telefono)">{{ item.telefono }}</span>
          </div>
          <div v-for="(item) in cellulari" :key="item.id">
            <span :id="item.id" @click="callNumberSelect(item.cellulare)">{{ item.referente }} {{
                item.cellulare
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modale -->
    <!-- Principal Map visible only mobile -->
    <b-row class="maps d-md-none tablet-screen-block">
      <b-col xs="12">
        <l-map
            style="height: 200px; width:100%"
            :zoom="zoom"
            :center="geolocation"
            gestureHandling
            :options="mapOptions"
        >
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="geolocation">
          </l-marker>
        </l-map>
      </b-col>
    </b-row>
    <!-- Principal Map visible only mobile -->
    <!-- Address visible only mobile -->
    <div class="section-address-mobile d-md-none tablet-screen-block"
         :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="address-mobile">
        <h3>Indirizzo</h3>
        <span>{{ via }} - {{ cap }} {{ comune }}</span>
      </div>
    </div>
    <!-- /Address visible only mobile -->

    <!-- Servizi -->
    <div class="services-mock2" v-if="servizi.length !== 0" :style="{'background-color': sfondo_2 }">
      <div class="container-80 line-services">
        <span :style="{'color': testo_sfondo_2 + '!important'}">Servizi</span>
        <hr :style="{ 'background-color': testo_sfondo_2 }">
        <div class="square" :style="{ 'background-color': testo_sfondo_2 }"></div>
      </div>
      <div
          class="services container-80">
        <b-row class="all-services">
          <b-col md="2" class="item-services" v-for="(value, propertyName) in servizi" :key="propertyName">

            <object
                :style="{'color': testo_sfondo_2, 'fill': testo_sfondo_2 }"
                type="image/svg+xml"
                class="logo-services-altair"
                :data="findIconServizio(propertyName)"
            >
            </object>

            <h4 class="title-service" :style="{'color': testo_sfondo_2+ '!important'}">{{ propertyName }}</h4>
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_2 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Necrologi -->
    <div :style="{ 'backgroundColor': sfondo_3 + '!important' }">
      <div class="last-necrologi-mock2 necrologi" :style="{ 'backgroundColor': sfondo_3 + '!important' }">
        <div class="line-services-necrologi">
          <span :style="{ 'color': testo_sfondo_3 + '!important' }">Ultimi Necrologi</span>
          <hr :style="{ 'backgroundColor': testo_sfondo_3 + '!important' }">
          <div class="square" :style="{ 'backgroundColor': testo_sfondo_3 + '!important' }"></div>
        </div>
        <div class="all-necrologi-altair" v-if="necrologi !== null && necrologi.length > 0">
          <div v-for="item in necrologi.slice(0,5)" :key="item.id" class="item-necrologio">
            <router-link :to="{name: 'DettaglioNecrologio', params: {id: item.id}}">
              <div class="necrologio_div" :style="{'background-color':sfondo_1}">
                <figure class="image is-128x128 center_in_div_necrologi">
                  <a href="#">
                    <v-lazy-image
                        class="necrologi_foto"
                        :src="getPhotoNecrologioFromCdn(item)"
                        :alt="item.nome"
                    />
                  </a>
                </figure>
                <br>
                <h1 class="nome_necrologio_center" :style="{'color': testo_sfondo_1 + '!important'}">{{
                    item.nome
                  }}</h1>
                <span class="eta_necrologio" :style="{'color': testo_sfondo_1 + '!important', 'opacity': 0.4}">{{
                    calculateYears(item.data_nascita, item.data_morte)
                  }} anni</span>
                <div class="eta_necrologio_div">
                  <span class="data_nascita_sinistra" :style="{'color': testo_sfondo_1 + '!important', 'opacity': 0.4}">{{
                      dataNecrologio(item.data_nascita)
                    }}</span>
                  <span class="data_morte_destra" :style="{'color': testo_sfondo_1 + '!important', 'opacity': 0.4}">{{
                      dataNecrologio(item.data_morte)
                    }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /Necrologi -->

    <!-- Manifesti -->
    <div class="manifesti-home-template-altair" v-if="manifesti !== null && manifesti.length > 0"
         :style="{'background-color': sfondo_1 }">
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 line-services-manifesti">
        <span :style="{ 'color': testo_sfondo_1 + '!important' }">Ultimi Manifesti</span>
        <hr :style="{ 'backgroundColor': testo_sfondo_1 + '!important' }">
        <div class="square" :style="{ 'backgroundColor': testo_sfondo_1 + '!important' }"></div>
      </div>

      <div class="last-manifesti container-80">
        <div class="last-manifesti-80">
          <b-row class="item-manifesti">
            <b-col v-for="item in manifesti.slice(0,3)" :key="item.id" md="4" cols="10" class="item-manifesto">

              <router-link :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

                <v-lazy-image
                    class="photo-manifesto"
                    :src="getPhotoManifestoFromCdn(item)"
                    :alt="item.nome_manifesto"
                />

              </router-link>
            </b-col>
          </b-row>
          <br>
        </div>
      </div>
    </div>

    <!-- Servizi Mobile -->
    <div class="services-mock2-mobile" v-if="servizi.length !== 0" :style="{'background-color': sfondo_2 }">
      <div class="container-80 line-services">
        <span :style="{'color': testo_sfondo_2 + '!important'}">Servizi</span>
        <hr :style="{ 'background-color': testo_sfondo_2 }">
        <div class="square" :style="{ 'background-color': testo_sfondo_2 }"></div>
      </div>
      <div class="d-md-none">
        <div :class="{ active_services : active_el === index }" class="img-center"
             v-for="(value, propertyName, index) in servizi" :key="propertyName" :id="'servizio'+index">

          <object
              type="image/svg+xml"
              class="logo-services-altair"
              :data="findIconServizio(propertyName)"></object>

          <div class="item-services-mobile">
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_2 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </div>
          <div class="btn btn-prev" :style="{'color': testo_sfondo_2 + '!important'}" aria-label="Previous slide"
               @click="activate(index - 1)">
            &#10094;
          </div>
          <div class="btn btn-next" :style="{'color': testo_sfondo_2 + '!important'}" aria-label="Next slide"
               @click="activate(index + 1)">
            &#10095;
          </div>
        </div>
      </div>
    </div>
    <!-- /Servizi Mobile -->


    <!-- Manifesti -->

    <!-- Mappa Principale -->
    <div class="principal-map" :style="{ 'backgroundColor': sfondo_3 + '!important' }">
      <div class="container-map-contact-mock2 container-80" :style="{ 'backgroundColor': sfondo_3 + '!important' }">
        <div class="info-agency-mock2">
          <div class="address">
            <Address class="icon-address-mock2" :style="{'fill': testo_sfondo_3 + '!important'}"></Address>
            <span class="info-address" :style="{'color': testo_sfondo_3 + '!important'}">{{ via }} -
                  {{ cap }}
                  {{ comune }}</span>
          </div>
          <div class="email">
            <Mail class="icon-email-mock2" :style="{'fill': testo_sfondo_3 + '!important'}"></Mail>
            <div>
              <span :style="{'color': testo_sfondo_3 + '!important'}">email:</span>
              <span :style="{'color': testo_sfondo_3 + '!important'}">{{ email }}</span>
            </div>
          </div>
          <div class="numbers">
            <Number class="icon-number-mock2" :style="{'fill': testo_sfondo_3 + '!important'}"></Number>
            <div>
              <span :style="{'color': testo_sfondo_3 + '!important'}">telefono:</span>
              <span :style="{'color': testo_sfondo_3 + '!important'}">{{ telefono }}</span>
            </div>
          </div>
          <div class="cellulari-mock2" v-if="cellulari !== undefined && cellulari.length > 0">
            <span :style="{'color': testo_sfondo_3 + '!important'}">cellulare:</span>
            <span v-for="item in cellulari" :key="item.id" :style="{'color': testo_sfondo_3 + '!important'}">
              {{ item.referente }} {{ item.cellulare }}
            </span>
          </div>
        </div>

        <!-- Mappa -->
        <div class="principal-map-mock2">
          <l-map
              class="item-map-sede-principale"
              style="height: 400px; width:100%;"
              :zoom="zoom"
              :center="geolocation"
              gestureHandling
              :options="mapOptions"
          >
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="geolocation"></l-marker>
          </l-map>
        </div>
      </div>
    </div>
    <!-- /Mappa Principale -->
    <!-- Altre sedi -->
    <div :style="{'background-color': sfondo_2}">
      <div v-if="sedi !== null && sedi.length > 0" class="other-locations"
           :style="{'background-color': sfondo_2 }">
        <h2 class="title-other-locations" :style="{'color': testo_sfondo_2 + '!important'}">Altre Sedi</h2>
        <div class="other-maps">
          <div>
            <router-link to="/contatti">
              <b-row class="single-map">
                <b-col
                    v-for="item in sedi"
                    :key="item.id"
                    md="4"
                    cols="8"
                    class="left-margin item-map-home">

                  <l-map class="item-map"
                         gestureHandling
                         :options="mapOptions"
                         :zoom="zoom"
                         :center="getNormalizedGeolocation(item.geolocation)"
                  >
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-marker :lat-lng="getNormalizedGeolocation(item.geolocation)"></l-marker>
                  </l-map>
                  <div class="over-map-altair">
                    <span>{{ item.address }}</span>
                    <span>{{ item.telefono }}</span>
                    <span class="cap">{{ item.cap.istat }} {{ item.cap.comune }}</span>
                  </div>
                </b-col>
              </b-row>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /Sezione Altre sedi-->
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import Telephone from '@/assets/TemplateImg/iconTel.svg?inline';
import Mail from '@/assets/TemplateImg/images-mock2/email.svg?inline'
import Address from '@/assets/TemplateImg/images-mock2/position.svg?inline'
import Number from '@/assets/TemplateImg/images-mock2/number.svg?inline'
import VLazyImage from "v-lazy-image/v2";
import LogoAgency from './LogoAgency.vue';
import {GestureHandling} from "leaflet-gesture-handling";
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';

import {
  iconaServizio,
  normalizeDataNascita,
  yearsCalculate,
  photoManifestoFromCDN,
  photoNecrologioFromCDN,
  normalizeGeolocation
} from "@/utils/util";

export default {
  name: "Homepage",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    // eslint-disable-next-line vue/no-unused-components
    GestureHandling,
    Telephone,
    VLazyImage,
    LogoAgency,
    Mail,
    Address,
    Number,
  },


  data() {

    return {
      slide: 0,
      sliding: null,
      text_visibility: null,
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      active_el: 0,
      mapOptions: {
        zoomSnap: 0.5,
        gestureHandling: true
      }
    }
  },

  computed: {

    ...mapGetters([
      'logo_azienda',
      'cellulari',
      'manifesti',
      'necrologi',
      'chi_siamo',
      'nome_azienda',
      'cap',
      'telefono',
      'comune',
      'via',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'texture',
      'email',
      'template',
      'geolocation',
      'icone',
      'sedi',
      'servizi',
      'foto_azienda',
      'foto_homepage',
    ]),
    createBorderBottom() {
      return ` 3px solid ${this.sfondo_1} `;
    },
    carosuelBcg() {
      return `linear-gradient(90deg, ${this.sfondo_3} 89.6%, ${this.sfondo_1} 10%)`;
    },
  },

  metaInfo() {
    return {
      title: 'Home - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },


  methods: {

    getNormalizedGeolocation(value) {
      return normalizeGeolocation(value)
    },

    findIconServizio(name) {
      return iconaServizio(name, this.icone)
    },

    dataNecrologio(data_nascita) {
      return normalizeDataNascita(data_nascita)
    },

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    getPhotoNecrologioFromCdn(item) {
      return photoNecrologioFromCDN(item)
    },

    getPhotoManifestoFromCdn(item) {
      return photoManifestoFromCDN(item)
    },

    activate: function (el) {
      if (el === 4) {
        el = 0;
      }
      if (el === -1) {
        el = 3;
      }
      this.active_el = el;
    },

    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false
    },

    showhideText: function () {

      this.text_visibility = document.getElementById("description");
      this.text_visibility.classList.remove("testo-chi-siamo");
      this.text_visibility.style.display = 'block';
      this.text_visibility.style.margin = '0 0 30px 0';
      this.text_visibility = document.getElementById("read-all-mobile");
      this.text_visibility.style.display = 'none';
      this.text_visibility = document.getElementById("read-all-desktop");
      this.text_visibility.style.display = 'none';
    },

    modalContact() {
      let modal = document.getElementById("contactModal");
      let span = document.getElementsByClassName("closeModal")[0];
      modal.style.display = "block";

      span.onclick = function () {
        modal.style.display = "none";
      }

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      }
    },

    callNumberSelect(telephone) {
      document.location.href = "tel:" + telephone;
    },

    handleScroll() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById("carousel-1").classList.add("carousel-1-scroll");
        document.getElementById("carousel-1").classList.remove("carousel-1-normal");
      } else {
        document.getElementById("carousel-1").classList.remove("carousel-1-scroll");
        document.getElementById("carousel-1").classList.add("carousel-1-normal");
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
@import '~@/assets/css/template_altair/homepage.css';
</style>

