<template>
  <div>
    <b-carousel class="carousel-1-normal" v-if="foto_homepage !== null && foto_homepage.length > 0"
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                :style="{ 'background-image': createHalfBcg }"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
          v-for="photo in foto_homepage"
          :key="photo.id"
          :img-src="photo.img"
          class="custom-slider-img img-slider-vega"
      ></b-carousel-slide>
    </b-carousel>

    <!-- Conttatti/email/indicazioni visibili solo mobile -->
    <div
        class="container-info-agency-mobile"
        :style="{'background-color': sfondo_3, 'background-image': 'none' }">
      <div class="container-80" :style="{'background-color': sfondo_3 }">
        <div class="center-contact-mobile" :style="{'background-color': sfondo_3 }">
          <div class="icon">
            <a href="https://www.google.de/maps?q=41.691306,12.779738&amp;z=20&amp;t=k">
              <IconaIndicazioniStradali :style="{'fill': testo_sfondo_3 + '!important'}"
                                        class="icon-indicazioni"></IconaIndicazioniStradali>
            </a>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Indicazioni</span>
          </div>
          <div class="icon" id="contactAgencyButton" @click="modalContact">
            <IconaTelefono :style="{'fill': testo_sfondo_3 + '!important'}"
                           class="icon-telephone-mobile"></IconaTelefono>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Telefoni</span>
          </div>
          <div class="icon">
            <a :href="`mailto:${email}`" class="mailto-email">
              <IconaEmail :style="{'fill': testo_sfondo_3 + '!important'}" class="icon-email"></IconaEmail>
            </a>
            <span :style="{ 'color': testo_sfondo_3 + '!important' }">Email</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /Conttatti/email/indicazioni visibili solo mobile -->

    <!-- Descrizione agenzia -->
    <div class="container-bg-agency-home"
         :style="{'background-color': sfondo_3 }"
    >
      <!-- <div v-if="texture" class="texture">
        <img :src="texture" alt="">
      </div> -->
      <div class="container-80 agency-detail">
        <img class="" src="~@/assets/TemplateImg/Template_vega/decorazione_up.svg" alt="">
        <b-row id="agency-image-not-update">
          <b-col md="12" xs="12">
            <h1 class="text-color nome-azienda-homepage" :style="{'color': testo_sfondo_3 + '!important'}">{{
                nome_azienda
              }}</h1>
          </b-col>
          <b-col md="12" xs="12" class="text-color">
            <span id="description" :style="{'color': testo_sfondo_3 + '!important'}"
                  v-html="chi_siamo">
            </span>
            <div v-if="chi_siamo !== null && chi_siamo.length > 600" class="container-80" id="read-all-desktop"
                 :style="{'color': testo_sfondo_3 + '!important'}">
              <span @click="showhideText" :style="{'color': testo_sfondo_3 + '!important'}">Leggi tutto</span>
            </div>
            <div v-if="chi_siamo !== null && chi_siamo.length > 370" class="container-80" id="read-all-mobile"
                 :style="{'color': testo_sfondo_3 + '!important'}">
              <span @click="showhideText" :style="{'color': testo_sfondo_3 + '!important'}">Leggi tutto</span>
            </div>
          </b-col>
        </b-row>
        <img class="" src="~@/assets/TemplateImg/Template_vega/decorazione_down.svg" alt="">
      </div>
      <br>
    </div>
    <!-- /Descrizione agenzia -->

    <!-- Modale  -->
    <div id="contactModal" class="modale" :style="{ 'color':testo_sfondo_1 ,'background-color': sfondo_1 }">
      <div class="modal-contact">
        <div class="closeModal">
          <span>&times;</span>
        </div>
        <div class="allSediHomepage">
          <h4 class="call-numbers">Chiama</h4>
          <div>
            <span @click="callNumberSelect(telefono)">{{ telefono }}</span>
          </div>
          <div v-for="(item) in sedi" :key="item.id">
            <span :id="item.id" @click="callNumberSelect(item.telefono)">{{ item.telefono }}</span>
          </div>
          <div v-for="(item) in cellulari" :key="item.id">
            <span :id="item.id" @click="callNumberSelect(item.cellulare)">{{ item.referente }} {{
                item.cellulare
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modale -->
    <!-- Map visible only mobile -->
    <b-row class="maps d-md-none">
      <b-col xs="12">
        <l-map style="height: 200px; width:100%"
               :zoom="zoom"
               :center="geolocation"
               gestureHandling
               :options="mapOptions"
        >
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="geolocation">
          </l-marker>
        </l-map>
      </b-col>
    </b-row>
    <!-- /Map visible only mobile -->
    <!-- Address visible only mobile -->
    <div class="section-address-mobile d-md-none" :style="{'background-color':sfondo_1, 'color':testo_sfondo_1}">
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="address-mobile">
        <h3>Indirizzo</h3>
        <span>{{ via }} - {{ cap }} {{ comune }}</span>
      </div>
    </div>
    <!-- /Address visible only mobile -->
    <div
        :style="{'background-color': sfondo_3 }"
    >
      <div class="necrologi"
           :style="{'background-color': sfondo_3 }">
        <h2 class="title-last-necrologi" :style="{ 'color': testo_sfondo_3 + '!important' }">Ultimi Necrologi</h2>

        <div
            class="all-necrologi-vega"
            v-if="necrologi !== null && necrologi.length > 0"
        >
          <div v-for="item in necrologi.slice(0,5)" :key="item.id" class="item-necrologio">

            <router-link :to="{name: 'DettaglioNecrologio', params: {id: item.id}}">

              <div class="necrologio_div_vega"
                   :style="{'background-color':sfondo_3, 'border': '3px solid' + sfondo_1 }">
                <figure class="image is-128x128-vega center_in_div_necrologi">
                  <div class="decorazione-necrologio-vega">
                    <img class="decorazione-vega" src="~@/assets/TemplateImg/Template_vega/decorazione_necrologio.svg"
                         alt="">
                    <v-lazy-image
                        class="is-rounded-vega necrologi_foto_vega"
                        :src="getPhotoNecrologioFromCdn(item)"
                        :alt="item.nome"
                    />
                  </div>
                </figure>
                <br>
                <h1 class="nome_necrologio_center" :style="{'color': testo_sfondo_3 + '!important'}">{{
                    item.nome
                  }}</h1>
                <span class="eta_necrologio" :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{
                    calculateYears(item.data_nascita, item.data_morte)
                  }} anni</span>
                <div class="eta_necrologio_div">
                  <span class="data_nascita_sinistra" :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{
                      dataNecrologio(item.data_nascita)
                    }}</span>
                  <span class="data_morte_destra" :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{
                      dataNecrologio(item.data_morte)
                    }}</span>
                </div>
              </div>
            </router-link>

          </div>
        </div>
      </div>
    </div>


    <div class="last-manifesti"
         v-if="manifesti !== null && manifesti.length > 0"
         :style="{'background-color': sfondo_1 }">
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="last-manifesti-80">
        <h2 class="title-manifesti" :style="{'color': testo_sfondo_1 + '!important'}">Ultimi Manifesti</h2>
        <b-row class="item-manifesti">
          <b-col v-for="item in manifesti.slice(0,3)" :key="item.id" md="4" cols="10" class="item-manifesto">

            <router-link :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

              <v-lazy-image
                  class="photo-manifesto-vega"
                  :src="getPhotoManifestoFromCdn(item)"
                  :alt="item.nome_manifesto"
              />

            </router-link>
          </b-col>
        </b-row>
        <br>
      </div>
    </div>

    <div
        v-if="servizi!== null && servizi.length !== 0"
        class="services"
        :style="{'background-color': sfondo_3 }"
    >
      <div class="container-80">
        <h2 class="title-services" :style="{'color': testo_sfondo_3 + '!important'}">Servizi</h2>
        <b-row class="all-services">
          <b-col md="2" class="item-services" v-for="(value, propertyName) in servizi" :key="propertyName">

            <object
                type="image/svg+xml"
                :style="{'color': testo_sfondo_2, 'fill': testo_sfondo_2 }"
                class="logo-services"
                :data="findIconServizio(propertyName)"
            >
            </object>

            <h4 class="title-service" :style="{'color': testo_sfondo_3 + '!important'}">{{ propertyName }}</h4>
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Servizi Mobile -->
      <div class="d-md-none">
        <div :class="{ active_services : active_el === index }" class="img-center"
             v-for="(value, propertyName, index) in servizi" :key="propertyName" :id="'servizio'+index">

          <object
              type="image/svg+xml"
              :style="{'background-color': sfondo_1 }"
              class="logo-services"
              :data="findIconServizio(propertyName)"></object>

          <div class="item-services-mobile">
            <div v-for="k in value" :key="k">
              <span :style="{'color': testo_sfondo_3 + '!important', 'opacity': 0.4}">{{ k }}</span>
            </div>
          </div>
          <div class="btn btn-prev" aria-label="Previous slide" @click="activate(index - 1)">
            &#10094;
          </div>
          <div class="btn btn-next" aria-label="Next slide" @click="activate(index + 1)">
            &#10095;
          </div>
        </div>
      </div>
      <!-- /Servizi Mobile -->
    </div>

    <div
        class="agency-map"
        :style="{'background-color': sfondo_1 }"
    >
      <div v-if="texture !== null" class="texture">
        <img :src="texture" alt="">
      </div>
      <div class="container-80 structure-map">
        <b-row class="all-services">
          <b-col md="3">
            <div class="info-maps">
              <h4
                  class="address"
                  :style="{'color': testo_sfondo_1 + '!important'}">{{ via }} -
                {{ cap }}
                {{ comune }}</h4>
              <span id="email" :style="{'color': testo_sfondo_1 + '!important'}">email:</span>
              <span :style="{'color': testo_sfondo_1 + '!important'}">{{ email }}</span>
              <span class="agency-telephone" :style="{'color': testo_sfondo_1 + '!important'}">telefono:</span>
              <span :style="{'color': testo_sfondo_1 + '!important'}">{{ telefono }}</span>
              <span
                  v-if="cellulari !== undefined && cellulari.length > 0"
                  class="agency-cellular"
                  :style="{'color': testo_sfondo_1 + '!important'}"
              >cellulare:</span>

              <span
                  v-for="item in cellulari" :key="item.id"
                  :style="{'color': testo_sfondo_1 + '!important'}"
              >
                  {{ item.referente }} {{ item.cellulare }}
              </span>
            </div>
          </b-col>
          <b-col md="7">
            <div class="maps map-sede-pricipale d-none d-md-block">
              <l-map
                  class="item-map-sede-principale"
                  style="height: 400px; width:100%;"
                  :zoom="zoom"
                  :center="geolocation"
                  gestureHandling
                  :options="mapOptions"
              >
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker :lat-lng="geolocation"></l-marker>
              </l-map>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Altre sedi -->
    <div :style="{'background-color': sfondo_3 }">
      <div v-if="sedi !== null && sedi.length > 0" class="other-locations"
           :style="{'background-color': sfondo_3 }">
        <h2 class="title-other-locations" :style="{'color': testo_sfondo_3 + '!important'}">Altre Sedi</h2>
        <div class="other-maps">
          <div>
            <router-link to="/contatti">
              <b-row class="single-map">
                <b-col
                    v-for="item in sedi"
                    :key="item.id"
                    md="4"
                    cols="8"
                    class="left-margin item-map-home">

                  <l-map class="item-map"
                         gestureHandling
                         :options="mapOptions"
                         :zoom="zoom"
                         :center="getNormalizedGeolocation(item.geolocation)"
                  >
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-marker :lat-lng="getNormalizedGeolocation(item.geolocation)"></l-marker>
                  </l-map>
                  <div class="over-map-vega"
                       :style="{'color': testo_sfondo_1 + '!important', 'background-color': sfondo_1}">
                    <span>{{ item.address }}</span>
                    <span>{{ item.telefono }}</span>
                    <span class="cap">{{ item.cap.istat }} {{ item.cap.comune }}</span>
                  </div>
                </b-col>
              </b-row>
            </router-link>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import VLazyImage from "v-lazy-image/v2";
import {GestureHandling} from "leaflet-gesture-handling";
import IconaIndicazioniStradali from '@/assets/TemplateImg/indicazioni_stradali.svg?inline'
import IconaEmail from '@/assets/TemplateImg/email.svg?inline'
import IconaTelefono from '@/assets/TemplateImg/telefono.svg?inline'
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import {mapGetters} from "vuex";
import {
  iconaServizio,
  normalizeDataNascita,
  yearsCalculate,
  photoManifestoFromCDN,
  photoNecrologioFromCDN,
  normalizeGeolocation
} from "@/utils/util";


export default {
  name: "Homepage",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    IconaIndicazioniStradali,
    IconaEmail,
    IconaTelefono,
    // eslint-disable-next-line vue/no-unused-components
    GestureHandling,
    VLazyImage
  },

  data() {

    return {
      slide: 0,
      sliding: null,
      text_visibility: null,
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      active_el: 0,
      mapOptions: {
        zoomSnap: 0.5,
        gestureHandling: true
      }
    }
  },

  computed: {

    ...mapGetters([
      'cellulari',
      'manifesti',
      'necrologi',
      'chi_siamo',
      'nome_azienda',
      'cap',
      'telefono',
      'comune',
      'via',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
      'texture',
      'email',
      'template',
      'geolocation',
      'icone',
      'sedi',
      'servizi',
      'foto_azienda',
      'foto_homepage',
    ]),

    createHalfBcg() {
      return `linear-gradient(to bottom, ${this.sfondo_1} 0%, ${this.sfondo_1} 50%, ${this.sfondo_3} 0% , ${this.sfondo_3} 100%)`;
    },

  },

  metaInfo() {
    return {
      title: 'Home - ' + this.nome_azienda,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo,
        },
        {property: 'og:title', content: this.nome_azienda},
        {property: 'og:site_name', content: ''},
        {
          property: 'og:description',
          content: this.chi_siamo
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },


  methods: {


    getNormalizedGeolocation(value) {
      return normalizeGeolocation(value)
    },

    findIconServizio(name) {
      return iconaServizio(name, this.icone)
    },

    dataNecrologio(data_nascita) {
      return normalizeDataNascita(data_nascita)
    },

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    getPhotoNecrologioFromCdn(item) {
      return photoNecrologioFromCDN(item)
    },

    getPhotoManifestoFromCdn(item) {
      return photoManifestoFromCDN(item)
    },

    activate: function (el) {
      if (el === 4) {
        el = 0;
      }
      if (el === -1) {
        el = 3;
      }
      this.active_el = el;
    },

    // eslint-disable-next-line no-unused-vars
    onSlideStart(slide) {
      this.sliding = true
    },
    // eslint-disable-next-line no-unused-vars
    onSlideEnd(slide) {
      this.sliding = false
    },

    showhideText: function () {

      this.text_visibility = document.getElementById("description");
      this.text_visibility.style.display = 'block';
      this.text_visibility = document.getElementById("read-all-mobile");
      this.text_visibility.style.display = 'none';
      this.text_visibility = document.getElementById("read-all-desktop");
      this.text_visibility.style.display = 'none';
    },


    modalContact() {
      let modal = document.getElementById("contactModal");
      let span = document.getElementsByClassName("closeModal")[0];
      modal.style.display = "block";

      span.onclick = function () {
        modal.style.display = "none";
      }

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      }
    },

    callNumberSelect(telephone) {
      document.location.href = "tel:" + telephone;
    },

    // handleScroll() {
    //   if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
    //     document.getElementById("carousel-1").classList.add("carousel-1-scroll");
    //     document.getElementById("carousel-1").classList.remove("carousel-1-normal");
    //   } else {
    //     document.getElementById("carousel-1").classList.remove("carousel-1-scroll");
    //     document.getElementById("carousel-1").classList.add("carousel-1-normal");
    //   }
    // },
  },

  // created() {
  //   window.addEventListener('scroll', this.handleScroll);
  // },
  // destroyed() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
}
</script>


<style scoped>
@import '~@/assets/css/template_vega/homepage.css';
</style>
